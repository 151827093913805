export const categoryfeatureswithgroups = (state = {values: []}, action) => {
    switch (action.type) {
      case "CATEGORY_FEATURES_WITH_GROUPS": {
        return { ...state, values: action.payload }
      }
      default: {
        return state
      }
    }
  }
  
import { combineReducers } from "redux"
import { units } from "./unitsReducer"
import { dataTypes } from "./dataTypesReducer"


const GeneralDataReducers = combineReducers({
  units,
  dataTypes
})

export default GeneralDataReducers

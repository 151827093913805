import { combineReducers } from "redux"
import { categories } from "./categoriesReducer"
import { categoryfeatures } from "./categoryFeaturesReducer"
import { categoryfeaturesgroups } from "./categoryFeaturesGroupsReducer"
import { categoryfeatureswithgroups } from "./categoryFeaturesWithGroupsReducer"
import { subcategories } from "./subCategoriesReducer"

const productsReducers = combineReducers({
  categories,
  subcategories,
  categoryfeatures,
  categoryfeaturesgroups,
  categoryfeatureswithgroups
})

export default productsReducers

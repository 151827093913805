const payment = (state = {values:{
    payLater: 0,
    wallet: 0,
    payLaterUsed: 0,
    isPayLater: false
}}, action) => {
    switch (action.type) {
      case "PAYMENT": {
        return  {...state, values : action.payload}
      }
      default: {
        return state
      }
    }
}

export default payment
import { history } from "../../../history"
import axios from "axios"
import { domain } from  "../../../backend"


export const loginWithJWT = (user, return_path, makeToast) => {
  // var username = user.email;
  // var password = user.password;
  // var basicAuth = 'Basic ' + window.btoa(username + ':' + password);
  // console.log(basicAuth);
  return dispatch => {
    axios.post(domain+"/v1/doctor/login", {
        // login: user.email,
        // password: user.password
      }, {
        auth: {
          username: user.email,
          password: user.password
        }
      })
      .then(response => {
        // console.log('Authenticated');
        if(response.data.status === 'done'){
          var loggedInUser
          var token = response.data.token
          axios.get(domain+"/v1/doctor/user", {
              // login: user.email,
              // password: user.passwordd
              headers: {
                "x-access-token" : token
              } 
            }).then(response => {
              // console.log(response.data)
              if (response.data) {
                loggedInUser = response.data
                // console.log(loggedInUser)
                localStorage.setItem("token", token)
                // console.log("token: "+localStorage.getItem("token"))
                dispatch({
                  type: "LOGIN_WITH_JWT",
                  payload: { loggedInUser, loggedInWith: "jwt", is_logged_in: true }
                })
                if (typeof return_path != 'undefined') {
                  history.push(return_path)
                }else{
                  history.push("/")
                }
              }
            }).catch(err => console.log(err))
        }else if(response.data.status === 'not_done'){
          if(response.data.data === 'user_not_found'){
            makeToast("User not found!")
          }else if(response.data.data === 'password_is_incorrect'){
              makeToast("Incorrect Password!")
          }else if(response.data.data === 'user_not_active'){
              makeToast("Account is not active, contact to CureSee support team")
          }
        }
      })
      .catch(err => 
        // console.log(err)
        makeToast("Something Went Wrong!")
      )
  }
}

export const logoutWithJWT = () => {
  return dispatch => {
    localStorage.removeItem("token")
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    history.push("/pages/login")
  }
}


export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

// export const domain = "http://192.168.0.157:5000"
// export const domain = "http://127.0.0.1:5000"
// export const razorPayKey = "rzp_test_Hv8hdt6mVpXhWX"

// export domain


//cureSee export const razorPayKey = "rzp_live_pHpSOELoWusDPH"
// export const razorPayKey = "rzp_live_Mgn7ksSKMtMWNb"
export const razorPayKey = "rzp_live_xQkYQRxu3juuhK"
export const domain = "https://api.amblyopiadoctor.com"
// export const domain = "https://jatinkaushik-effective-space-giggle-wrw4pjv7j6p3v65j-5000.preview.app.github.dev"
export const subcategories = (state = {values: []}, action) => {
    switch (action.type) {
      case "SUBCATEGORIES": {
        return { ...state, values: action.payload }
      }
      default: {
        return state
      }
    }
  }
  
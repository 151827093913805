var initialState = JSON.parse(localStorage.getItem('tabs'))
if (initialState === null || initialState === undefined){
  initialState = {
    ids: [
      'home', 'test', 'products'
    ],  
    data: {
      'home': [],
      'test': [],
      'products': []
    }
  }
}

// to add tab = {id, data}
// to delete tab = {id}
// to add data = {id, data}

const tabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TAB":
      state.data[action.id] = action.data
      state = { ...state, ids: [...state.ids, action.id]}
      localStorage.setItem('tabs', JSON.stringify(state))
      return state
    case "DELETE_TAB":
      var data = {...state}
      data.ids = state.ids.filter((el)=> el !== action.id)
      delete data.data[action.id]
      localStorage.setItem('tabs', JSON.stringify(data))
      return data
    case "ADD_TAB_DATA":
      state.data[action.id] = action.data
      localStorage.setItem('tabs', JSON.stringify(state))
      return state
    default:
      localStorage.setItem('tabs', JSON.stringify(state))
      return state
  }
}

export default tabsReducer
